import { h, resolveComponent } from 'vue'
import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'
import EmptyLayout from '@/layouts/EmptyLayout'

const routes = [
  {
    path: '/',
    name: '',
    component: DefaultLayout,
    redirect: '/browse',
    children: [
      {
        path: '/browse',
        name: 'Genealogy',
        redirect: '/browse/showcase/',
        component: EmptyLayout,
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: 'blog/',
            name: 'Blog',
            component: () => import('@/views/gen_admin/Blog'),
          },
          {
            path: 'showcase/',
            name: 'Showcase',
            component: () => import('@/views/gen_admin/AvailableTasks.vue'),
          },
          {
            path: 'new_showcase/',
            name: 'NewShowcase',
            component: () =>
              import('@/views/gen_admin/AvailableTasksOnlineArchive.vue'),
          },
          {
            path: 'new_column/',
            name: 'NewСolumn',
            component: () =>
              import('@/views/gen_admin/AvailableTasksOfflineArchive.vue'),
          },
          {
            path: 'report/',
            name: 'Report',
            component: () =>
              import('@/views/gen_admin/AvailableTasksReport.vue'),
          },
          {
            path: 'comprehensive_research/',
            name: 'Comprehensive research tasks',
            component: () => import('@/views/gen_admin/AvailableCRTasks.vue'),
          },
          {
            path: 'comprehensive_research/:id',
            name: 'Comprehensive research tasks detail',
            component: () =>
              import('@/views/gen_admin/AvailableCRTaskDetail.vue'),
          },
          {
            path: 'interview_showcase/',
            name: 'Interview',
            component: () =>
              import('@/views/gen_admin/AvailableTasksInterview.vue'),
          },
          {
            path: 'interview_showcase/:id',
            name: 'Available genealogical task from interview',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetailInterview.vue'),
          },
          {
            path: 'gen_showcase/',
            name: 'Gen_showcase',
            component: () => import('@/views/gen_admin/AdminShowcaseTasks.vue'),
          },
          {
            path: 'create_op_tasks/',
            name: 'Create op tasks',
            component: () => import('@/views/gen_admin/CreateOPTasks.vue'),
          },
          {
            path: 'gen_showcase/:id',
            name: 'Admin showcase',
            component: () =>
              import('@/views/gen_admin/AdminShowcaseDetail.vue'),
          },
          {
            path: 'propose_for_user/:id',
            name: 'Admin showcase2',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetailPropose.vue'),
          },
          {
            path: 'interview_propose_for_user/:id',
            name: 'Interview propose',
            component: () =>
              import(
                '@/views/gen_admin/AvailableTaskDetailInterviewPropose.vue'
              ),
          },
          {
            path: 'auto_propose_for_user/:id',
            name: 'Auto propos showcase',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetailAutoPropose.vue'),
          },
          {
            path: 'new_showcase/:id',
            name: 'New available genealogical task',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetailOnlineArchive.vue'),
          },
          {
            path: 'showcase/:id',
            name: 'Available genealogical task',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetail.vue'),
          },
          {
            path: 'new_column/:id',
            name: 'Available genealogical task from new column',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetailOfflineArchive.vue'),
          },
          {
            path: 'report/:id',
            name: 'Available genealogical task from report',
            component: () =>
              import('@/views/gen_admin/AvailableTaskDetailReport.vue'),
          },
          {
            path: 'my_tasks/',
            name: 'My tasks',
            component: () => import('@/views/gen_admin/UserTasks.vue'),
          },
          {
            path: 'instruction/',
            name: 'Instruction',
            component: () => import('@/views/gen_admin/Instruction.vue'),
          },
          {
            path: 'tools/',
            name: 'Tools',
            component: () => import('@/views/gen_admin/Tools.vue'),
          },
          {
            path: 'download_from_familysearch/',
            name: 'DownloadFromFamilySearch',
            component: () =>
              import('@/views/gen_admin/DownloadFromFamilySearch.vue'),
          },
          {
            path: 'create_bp/:id',
            name: 'CreateBP',
            component: () => import('@/views/gen_admin/CreateBP.vue'),
          },
          {
            path: 'genealogical_task/:id',
            name: 'GenealogicalTask',
            component: () =>
              import('@/views/gen_admin/AvailableGenealogicalTask.vue'),
          },
          {
            path: 'interview_task/:id',
            name: 'InterviewTask',
            component: () => import('@/views/gen_admin/InterviewTask.vue'),
          },
          {
            path: 'text_improvement/',
            name: 'TextImprovement',
            component: () => import('@/views/gen_admin/TextImprovement.vue'),
          },
          {
            path: 'incoming_documents/',
            name: 'Входящие документы',
            component: () => import('@/views/gen_admin/Documents.vue'),
          },
          {
            path: 'added_incoming_documents/',
            name: 'Добавить входящий документ (офлайн)',
            component: () => import('@/views/gen_admin/IncomingMails.vue'),
          },
          {
            path: 'find_incoming_documents/',
            name: 'Поиск документов',
            component: () => import('@/views/gen_admin/FindDocuments.vue'),
          },
          {
            path: 'example/',
            name: 'Examples',
            component: () => import('@/views/gen_admin/Example.vue'),
          },
          {
            path: 'example/:id',
            name: 'Ex',
            component: () => import('@/views/gen_admin/ExampleDetail.vue'),
          },
          {
            path: 'user_document_list/:id',
            name: 'Doc_user',
            component: () => import('@/views/gen_admin/UsersDocumentList.vue'),
          },
          {
            path: 'manager_document_list/:id',
            name: 'Doc_manager',
            component: () =>
              import('@/views/gen_admin/ManagerDocumentList.vue'),
          },
          {
            path: 'my_tasks/:id',
            name: 'My task',
            component: () => import('@/views/gen_admin/UserTaskDetail.vue'),
          },
          {
            path: 'check_tasks/',
            name: 'Tasks for check',
            component: () => import('@/views/gen_admin/ManagerTasks.vue'),
          },
          {
            path: 'profile',
            name: 'Profile',
            component: () => import('@/views/pages/Profile.vue'),
          },
          {
            path: 'check_tasks/:id',
            name: 'Task for check',
            component: () => import('@/views/gen_admin/ManagerTaskDetail.vue'),
          },
          {
            path: 'za_tasks/:id',
            name: 'ZA task for check',
            component: () =>
              import('@/views/gen_admin/ManagerZaTaskDetail.vue'),
          },
          {
            path: 'board/',
            name: 'Board',
            component: () => import('@/views/gen_admin/AdminBoard.vue'),
          },
          {
            path: 'admin_panel/',
            name: 'Admin Panel',
            component: () => import('@/views/gen_admin/AdminPanelForBoard.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/pages',
    redirect: '/pages/404',
    name: 'Pages',
    component: {
      render() {
        return h(resolveComponent('router-view'))
      },
    },
    children: [
      {
        path: 'humanbank',
        name: 'Human Bank',
        component: () => import('@/views/humanbank/HumanBank.vue'),
      },
      {
        path: '404',
        name: 'Page404',
        component: () => import('@/views/pages/Page404'),
      },
      {
        path: 'login',
        name: 'Login',
        meta: {
          requiresNotAuth: true,
        },
        component: () => import('@/views/pages/Login'),
      },
      {
        path: 'logout',
        name: 'Logout',
        component: () => import('@/views/pages/Logout'),
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/pages/404',
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
